import React, { useEffect, useState } from 'react';
import gql from 'not-graphql-tag';
import { useTranslation } from '../hooks/useTranslation';
import {
  Grid,
  Paper,
  Card,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/';
import Alerts from '../components/alerts/index';
import PGEButton from '../components/buttons';
import { navigate, useLocation } from '@reach/router';
import * as queryString from 'query-string';
import colors from '../themes/main-colors';
import apolloClient from '../lib/apolloClient';
import {
  LeadDiscardReason,
  LeadDetailsEmailUpdateRequest,
} from '../__generated__/pge-types';
import Typography from '@material-ui/core/Typography';
import ROUTES from '../routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comfirmEmailPage: {
      margin: '70px auto',
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
      '& h2': {
        fontWeight: 300,
        margin: '-15px 0 0',
      },
    },
    doneBtnHolder: {
      padding: 10,
      display: 'flex',
    },
  }),
);

type QueryParams = {
  token: string;
  reject: string;
};

export const ClientProgramPromo = () => {
  const location = useLocation();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const queryParams = queryString.parse(location.search) as QueryParams;
  const { token, reject } = queryParams;

  useEffect(() => {
    const encodedToken = encodeURIComponent(token).replace(/%20/g, '%2B');
    reject !== 'true'
      ? void sendClientPromoRequest({
          encryptedString: encodedToken,
          outcome: 'A',
          notes: 'Email Channel',
        })
      : void sendClientPromoRequest({
          encryptedString: encodedToken,
          outcome: 'R',
          notes: 'Email Channel',
          discardReasonCd: LeadDiscardReason.LeadDiscardNo,
        });
  }, []);

  const sendClientPromoRequest = async (
    payload?: LeadDetailsEmailUpdateRequest,
  ) => {
    const updateLeadEmailDetails = gql`
      mutation updateLeadEmailDetails(
        $payload: LeadDetailsEmailUpdateRequest!
      ) {
        updateLeadEmailDetails(payload: $payload) {
          leadId
        }
      }
    `;
    await apolloClient.mutate<{
      updateLeadEmailDetails: LeadDetailsEmailUpdateRequest;
    }>({
      mutation: updateLeadEmailDetails,
      variables: {
        payload,
      },
    });
  };
  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={11} sm={8} md={6}>
          <Paper component={'main'} className={classes.comfirmEmailPage}>
            <Card>
              <Alerts
                disableColorOverride={true}
                isOpen
                severity={'success'}
                variant={'outlined'}
                title={
                  reject === 'true'
                    ? t('PAPERLESS_EMAIL_CONFIRMATION_MESSAGE')
                    : t('PAPERLESS_EMAIL_CONFIRMATION_MESSAGE_REJECT_FALSE')
                }
                message={
                  <>
                    <Grid container spacing={2}>
                      <Grid className={classes.doneBtnHolder}>
                        <PGEButton
                          onClick={async () => {
                            await navigate(ROUTES.SIGN_IN);
                          }}
                        >
                          <Typography variant={'h4'} noWrap>
                            {t('DONE')}
                          </Typography>
                        </PGEButton>
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientProgramPromo;
